import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Seo from '../components/Seo';

import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EventsPage = ({ data }) => {
  const title = 'Concert Dates';
  const slug = '/concert-dates';
  const events = data.allPrismicEvent ? data.allPrismicEvent.edges : null;
  const description = 'JLP tour and concert information. Keep checking back for new dates!';

  return (
    <div>
      <h1
        className="page-header"
        data-aos="fade-in"
        data-aos-delay="150"
      >
        {title}
      </h1>
      <div className="page-content no-bg no-padding">
        {events.length > 0 && events.map(({ node }) => (
          <article
            className="concert"
            data-aos="fade-up"
            data-aos-offset="50"
            key={node.prismicId}
          >
            <div className="row">
              <div className="columns col-1 small-12 medium-6">
                <header>
                  <small className="ish">{node.data.country}
                  <span className="hide-for-medium-up"> - {node.data.city}</span></small>
                  <h2>
                    <time
                      dateTime={node.data.date_alt}
                      className="bumper success"
                    >
                      {node.data.date_normal}
                    </time>
                    <span>{node.data.city}</span>
                  </h2>
                </header>
              </div>
              <div className="columns col-2 small-12 medium-6">
                <span className="venue">
                  <Link to={`/content/concert-dates/${node.prismicId}/${node.slugs[0]}`}>
                    {node.data.venue}
                    <br />
                    {node.data.city}, {node.data.country}
                  </Link>
                </span>
                <span
                  className="description"
                  dangerouslySetInnerHTML={{ __html: node.data.description.html }}
                />
                {node.data.tickets
                  && (
                <span className="tickets">
                  <a
                    href={node.data.tickets.url}
                  >
                      Get tickets
                  </a>
                </span>
                )}
              </div>
            </div>
          </article>
        ))}
        <div className="subnav">
          <Link to="/concert-dates/past">
            Past Shows&nbsp;
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Link>
        </div>
        {!events.length && (
          <span className="results-msg">
            0 concerts found.
          </span>
        )}
        <Seo
          slug={slug}
          title={title}
          description={description}
        />
      </div>
    </div>
  );
};

EventsPage.propTypes = {
  data: PropTypes.object,
};

export default EventsPage;

export const query = graphql`
  {
    allPrismicEvent(
      sort: {
        fields:[
          data___date_beginning
        ],
        order: ASC
      },
      filter: {
        date_beginning_year: {
          gt : 2019
        }
      }
    ) {
      edges {
        node {
          prismicId
          slugs
          date_beginning_year
          data{
            city
            state
            country
            venue
            tickets {
              url
            }
            date_normal: date_beginning(
              formatString: "MMM D, YYYY"
            )
            date_alt: date_beginning(
              formatString: "YYYY-MM-DD"
            )
            description {
              html
              text
            }
          }
        }
      }
    }
  }
`;
